const strings: {[key: string]: {[key: string]: string }} = {
    en: {
        name: "Name",
        type: "Type",
        id: "ID",
        ip_address: "IP Address",
        ip_addresses: "IP Addresses",
        mac_addresses: "MAC Addresses",
        mac_address: "MAC Address",
        version: "Version",
        firmware_version: "Firmware Version",
        firmware: "Firmware",
        patching_level: "Installed Patches",
        patching: "Patching",
        vendor: "Vendor",
        model: "Model",
        operating_system: "Operating System",
        operating_system_version: "Operating System Version",
        serial_number: "Serial Number",
        product_version: "Product Version",
        product_family: "Product Family",
        product_model: "Product Model",
        installation_id: "Installation ID",
        instance_id: "Instance ID",
        installed_software: "Installed Software",
        hardware_order_number: "Hardware Order Number",
        memory_card_serial: "Memory Card Serial Number",
        engine_name: "Engine Name",
        module_prod_date: "Production Date",
        module_filesystem_version: "Filesystem Version",
        module_hardware_revision: "HW Revision",
        module_bootloader_2_version: "Bootloader 2 Version",
        module_firmware_version: "Firmware Version",
        engine_type: "Engine Type",
        file_name: "File",
        module_id: "Module ID",
        module_software_version: "Software Version",
        engine_number: "Engine Number",
        module_serial: "Serial Number",
        module_diag_mcu_sw_version: "Diag. MCU. Version",
        timestamp: "Timestamp",
        rct_serial: "RCT Serial",
        part_number: "Part Number",
        project_name: "Project Name",
        project_number: "Project Number",
        project_serial: "Project Serial",
        manufacturing_time: "Manufacturing Time",
        operator_id: "Operator ID",
        manufacturing_date: "Manufacturing Date",
        manufacture_date: "Manufacture Date",
        scan_station: "Scan Station",
        part_serial: "Part Serial",
        nameplate_serial: "Nameplate Serial",
        operation_country: "Operation Country",
        operating_status: "Operating Status",
        category: "Category",
        load_type: "Load Type",
        warranty: "Warranty Responsible Network Company",
        project_manager: "Project Manager",
        warranty_manager: "Warranty Manager",
        host_name: "Host Name",
        hostname: "Host Name",
        os_type: "OS Type",
        os: "Operating System",
        os_version: "OS Version",
        device_type: "Device Type",
        end_of_sale_date: "End of Sale Date",
        end_of_support_date: "End of Support Date",
        end_of_sale: "End of Sale",
        end_of_support: "End of Support",
        start_of_sale: "Start of Sale",
        series: "Series",
        networking: "Networking",
        material_manufacturer_serial: "Material Manufacturer Serial",
        component_manufacturer_oem: "Component Manufacturer OEM",
        component_material_number: "Component Material Number",
        group: "Group",
        physical_address: "Physical Address",
        description: "Description",
        time_zone: "Time Zone",
        status: "Status",
        region: "Region",
        comments: "Comments",
        instance_name: "Instance Name",
        short_description: "Short Description",
        related_item: "Related Item",
        discovery_year: "Discovery Year",
        discovery_month: "Discovery Month",
        patch_status: "Patch Status",
        vendor_article_link: "Vendor Article Link",
        vendor_patch_link: "Vendor Patch Link",
        patch_hash: "Patch Hash",
        serial: "Serial",
        created_date: "Created Date",
        sw_ip: "IP Address",
        interface: "Interface",
        product_name: "Product",
        vendor_name: "Vendor",
        vlan: "VLAN",
        last_seen: "Last Seen",
        first_seen: "First Seen",
        created_by: "Created By",
        created_at: "Created At",
        last_updated: "Last Updated",
        firmware_and_os_version: "FW & OS Version",
        asset_type: "Asset Type",
        internal_source_id: "Internal Source ID",
        external_source_id: "External Source ID",
        software_version: "Software Version",
        mycyber_product_id: "Product ID",
        mycyber_business_unit_id: "Business Unit ID",
        source: "Source",
        zone_id: "Zone ID",
        purdue_level: "Purdue Level",
        part: "Part Serial",
        nameplate: "Nameplate Serial",
        project: "Project Serial",
        additional_versions: "Additional Versions",
        imagefile_name: "Imagefile Name",
        component_ids: "Component IDs",
        last_backup_date: "Last Backup Date",
        model_name: "Model Name",
        model_series: "Model Series",
        model_version: "Model Version",
        creation_data_created_by: "Created By",
        creation_data_update_date: "Updated Date",
        creation_data_created_date: "Created Date",
        creation_data_source_type: "Source Type",
        version_hardware: "Hardware Version",
        version_software: "Software Version",
        version_firmware: "Firmware Version",
        serial_numbers_part: "Part Serial Number",
        serial_numbers_project: "Project Serial Number",
        network_information_ip_addresses: "IP Addresses",
    }
}

function GetLanguage(): string {
    // Fixed to English for now
    return "en";
}

export function GetString(key: string): string {
    return strings[GetLanguage()][key];
}
