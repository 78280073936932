import axios from "axios";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {GridSortModel} from "@mui/x-data-grid";
import {useAuth} from "../auth/auth.hooks";
import {AssetGroupsInfo, DropDownItem} from "./assetGroups.types";


export const useAssetGroups = (
    limit: number,
    page: number,
    search: string[] | undefined,
    sort: GridSortModel | undefined,
    columnFilter: object | undefined
): UseQueryResult<AssetGroupsInfo[]> => {
    const { accessToken, config } = useAuth();
    let sortField: string | undefined;
    let sortDir: number | undefined;

    if(sort !== undefined && sort.length > 0) {
        sortField = sort[0].field;
        sortDir = sort[0].sort === "asc" ? 1 : -1;
    }

    return useQuery({
        queryKey: ['asset_groups', { 'limit': limit, 'page': page, 'search': search, 'sort': sort }, columnFilter],
        queryFn:() =>
            axios
                .get<AssetGroupsInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_groups`,
                    { params: {
                            limit,
                            page,
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(sortField !== undefined ? { sort: sortField } : {}),
                            ...(sortDir !== undefined ? { sort_dir: sortDir } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),

        staleTime: 60000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken),
    })
}

export const useProductDetails = (vendorId: number): UseQueryResult<DropDownItem[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['products_dd', {'vendorId': vendorId,}],
        queryFn:() =>
            axios
                .get<DropDownItem[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/products_dd`,
                    { params: {
                        vendor_id: vendorId,
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),

            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        })
}

export const useVendorDetails = (searchValue: string): UseQueryResult<DropDownItem[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['vendors_dd', {'search': searchValue,}],
        queryFn:() =>
            axios
                .get<DropDownItem[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/vendors_dd`,
                    { params: {
                        search: searchValue,
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),

            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        })
}

export const useAssetGroupCount = (
    search: string[] | undefined,
    columnFilter: object | undefined
): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['asset_groups_rows', { 'search': search }, columnFilter],
        queryFn:() =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_groups/rows`,
                    { params: {
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),

            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}
