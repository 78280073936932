export function validateMacAddress(mac: string): boolean {
    // If string is empty, don't validate it
    if(!mac) return true;

    /* MAC addresses can either be separated into 6 sets of 2 hexadecimal digits separated by
     * colon (:) or dash (-), or 3 sets of 4 hexadecimal digits separated by a dot (.)
     */

    let regex = /^[0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}$/i

    let result = regex.test(mac)

    if (!result) {
        regex = /^[0-9a-f]{4}[.][0-9a-f]{4}[.][0-9a-f]{4}$/i

        result = regex.test(mac)
    }

    return result
}

export enum sourceTypes {
    Unknown = -1,
    UNIC = 0,
    AssetScan = 1,
    SESProp = 2,
    SESHybrid = 3,
    ESO = 4,
    Plantnet = 5,
    PCS = 6,
    SCR = 7,
    sWOIS = 8,
    WDCU = 9,
    Psirt = 10,
}

export const sourceTypeDatas: {[key in sourceTypes]: any} = {
    [sourceTypes.UNIC]: {
        "name": "Module Scan Data",
        "client": "modules",
        "api": "modules",
        "readonly_roles": ["otam_admin", "otam_engine_data", "otam_engine_data_read"],
        "roles": ["otam_admin", "otam_engine_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.AssetScan]: {
        "name": "Asset Scan Data",
        "client": "asset_scan",
        "api": "asset_scan",
        "readonly_roles": ["otam_admin", "otam_asset_scan_data", "otam_asset_scan_data_read"],
        "roles": ["otam_admin", "otam_asset_scan_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.SESProp]: {
        "name": "SES Propulsion Asset Data",
        "client": "ses_prop",
        "api": "ses_prop",
        "readonly_roles": ["otam_admin", "otam_ses_data", "otam_ses_data_read"],
        "roles": ["otam_admin", "otam_ses_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.SESHybrid]: {
        "name": "SES Hybrid Asset Data",
        "client": "ses_hybrid",
        "api": "ses_hybrid",
        "readonly_roles": ["otam_admin", "otam_ses_data", "otam_ses_data_read"],
        "roles": ["otam_admin", "otam_ses_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.ESO]: {
        "name": "ES&O Asset Data",
        "client": "eso",
        "api": "eso",
        "readonly_roles": ["otam_admin", "otam_eso_data", "otam_eso_data_read"],
        "roles": ["otam_admin", "otam_eso_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.Plantnet]: {
        "name": "Plantnet Asset Data",
        "client": "plantnet",
        "api": "plantnet",
        "readonly_roles": ["otam_admin", "otam_plantnet_data", "otam_plantnet_data_read"],
        "roles": ["otam_admin", "otam_plantnet_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.PCS]: {
        "name": "PCS Asset Data",
        "client": "pcs",
        "api": "pcs",
        "readonly_roles": ["otam_admin", "otam_pcs_data", "otam_pcs_data_read"],
        "roles": ["otam_admin", "otam_pcs_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.SCR]: {
        "name": "SCR Asset Data",
        "client": "scr",
        "api": "scr",
        "readonly_roles": ["otam_admin", "otam_scr_data", "otam_scr_data_read"],
        "roles": ["otam_admin", "otam_scr_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.sWOIS]: {
        "name": "Marine sWOIS Asset Data",
        "client": "swois",
        "api": "swois",
        "readonly_roles": ["otam_admin", "otam_swois_data", "otam_swois_data_read"],
        "roles": ["otam_admin", "otam_swois_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.WDCU]: {
        "name": "WDCU Asset Data",
        "client": "wdcu",
        "api": "wdcu",
        "readonly_roles": ["otam_admin", "otam_wdcu_data", "otam_wdcu_data_read"],
        "roles": ["otam_admin", "otam_wdcu_data"],
        "allowedViews": ["assets"],
    },
    [sourceTypes.Psirt]: {
        "name": "Registered Product Assets",
        "client": "asset_groups",
        "api": "asset_groups",
        "readonly_roles": ["otam_admin", "otam_psirt"],
        "roles": ["otam_admin", "otam_psirt"],
        "allowedViews": ["asset_groups"],
    },
    [sourceTypes.Unknown]: {  // Used when adding new assets
        "name": "-",
        "client": "",
        "api": "",
        "readonly_roles": [],
        "roles": [],
    },
}

export function SourceTypeAllowedInView(AllowedViews: string[], View: string): boolean {
    if(AllowedViews === undefined) return false;
    for(let i = 0; i < AllowedViews.length; i += 1) {
        if(AllowedViews[i] === View) {
            return true;
        }
    }
    return false;
}
