import React, {MouseEventHandler, useState} from "react";
import {Alert, Box, Button, CircularProgress, Collapse, Container, Divider, Link, Typography} from "@mui/material";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Banner} from "../../banner/banner.component";
import {EnginesGrid} from "../../engines/engines.component";
import {useInstallationDetails} from "../installation.hooks";
import {InstallationInfo} from "../installation.types";
import {GetString} from "../../../localization";
import {AssetScanDataGrid} from "../../assetScan/assetScan.component";
import {useEngineInfoCount} from "../../engines/engine.hooks";
import {useAssetScanInfoCount} from "../../assetScan/assetScan.hooks";
import {AuthenticateRolesLocally, RoleAuthResult} from "../../auth/rolesAuth";
import {useAuth} from "../../auth/auth.hooks";
import {sourceTypes} from "../../misc";
import {AssetDataGrid} from "../../assetData/assetData.component";
import {useAssetDataInfoCount} from "../../assetData/assetData.hooks";

interface InfoFieldPairProps {
    title: string;
    data: string;
}

interface AssetInfoBoxProps {
    name: string;
    data: InstallationInfo;
    content: string[];
    exportButton: JSX.Element;
}

interface ApplicationLinksBoxProps {
    id: string;
}

interface ShowTabProps {
    tabConfig: tabSettings[];
    tabVisibleIndex: number;
    id: string;
}

interface TabButtonProps {
    name: string;
    icon: string;
    thisTab: number;
    currentTab: number;
    onButtonClick: MouseEventHandler<HTMLButtonElement>;
}

enum TabType {
    NA = -1,
    EngineModules = 0,
    AssetScanData = 1,
    ESO = 2,
    SESProp = 3,
    SESHybrid = 4,
    Plantnet = 5,
    SCR = 6,
    WDCU = 7,
    sWOIS = 8,
    PCS = 9,
}

type tabSettings = {
    name: string; // Name of the tab
    iconPath: string; // Path to tab icon
    itemCount: number; // The amount of items this asset type has (if any), used to determine if tab should be shown
    tabType: TabType; // Which type of tab is this?
    visibleIndex: number; // Index of tab relative to the visible ones (-1 = not visible)
    roles: string[];
    access: boolean;
    [key: string]: (number | string | string[] | boolean);
}

const installationInfoContents: string[] = [
    'name',
    'installation_id',
    'project_number',
    'category',
    'operation_country',
    'operating_status',
    'type',
    'load_type',
    'warranty',
    'project_manager',
    'warranty_manager',
]

function InfoFieldPair({title, data}: InfoFieldPairProps): JSX.Element {
    const text = <Typography style={{fontSize: "medium", whiteSpace: "normal", wordWrap: "break-word"}} sx={{marginTop: 0, marginBottom: 1.4, marginLeft: 0, marginRight: 0, padding: 0}}>
        {data === null ? ("-") : (data)}
    </Typography>

    return <Box>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            <strong>{title}</strong>
        </Typography>
        {text}
        <br/>
    </Box>
}

function FormatFieldData(data: number | string | string[]): string {
    if(Array.isArray(data)) {
        const list = data.toString();
        return list.split(",").join("\n");
    }

    return data as string;
}

function AssetInfoBox({name, data, content, exportButton}: AssetInfoBoxProps): JSX.Element {
    const [collapse, setCollapse] = useState(false);

    return (
        <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: "pointer"}}>
            <Box sx={{display: "flex",  justifyContent: "space-between" }} onClick={() => {
                setCollapse(!collapse);
            }}>
                <Box sx={{display: "flex" }}>
                    <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                        {name}
                    </Typography>
                    <Box sx={{ml: "10px"}}>
                        {collapse ? <KeyboardArrowDownIcon sx={{pt: "0px"}}/> : <KeyboardArrowUpIcon sx={{pt: "0px"}}/>}
                    </Box>
                </Box>
                {exportButton}
            </Box>
            <Collapse in={!collapse} sx={{cursor: "auto"}}>
                <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                <Box style={{display: "flex"}}>
                    <Box style={{float: "left", width: "30%"}}>
                        <InfoFieldPair title={GetString(content[0])} data={FormatFieldData(data[content[0]])}/>
                        <InfoFieldPair title={GetString(content[1])} data={FormatFieldData(data[content[1]])}/>
                        <InfoFieldPair title={GetString(content[2])} data={FormatFieldData(data[content[2]])}/>
                        <InfoFieldPair title={GetString(content[3])} data={FormatFieldData(data[content[3]])}/>
                    </Box>
                    <Box style={{float: "left", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[4])} data={FormatFieldData(data[content[4]])}/>
                        <InfoFieldPair title={GetString(content[5])} data={FormatFieldData(data[content[5]])}/>
                        <InfoFieldPair title={GetString(content[6])} data={FormatFieldData(data[content[6]])}/>
                        <InfoFieldPair title={GetString(content[7])} data={FormatFieldData(data[content[7]])}/>
                    </Box>
                    <Box style={{float: "right", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[8])} data={FormatFieldData(data[content[8]])}/>
                        <InfoFieldPair title={GetString(content[9])} data={FormatFieldData(data[content[9]])}/>
                        <InfoFieldPair title={GetString(content[10])} data={FormatFieldData(data[content[10]])}/>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}

function ApplicationLinksBox({id}: ApplicationLinksBoxProps): JSX.Element {
    const [collapse, setCollapse] = useState(true);

    const link = `https://cib.data.wartsila.com/installations/${id}`;

    return (
        <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: "pointer"}}>
            <Box sx={{display: "flex"}} onClick={() => {
                setCollapse(!collapse);
            }}>
                <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                    Application Links
                </Typography>
                <Box sx={{ml: "10px"}}>
                    {collapse ? <KeyboardArrowDownIcon sx={{pt: "0px"}}/> : <KeyboardArrowUpIcon sx={{pt: "0px"}}/>}
                </Box>
            </Box>
            <Collapse in={!collapse} sx={{cursor: "auto"}}>
                <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                <ul className="homePageList">
                    <li><Link className="genericLink" target="_blank" href={link}>Customer Installed Base</Link></li>
                </ul>
            </Collapse>
        </Box>
    );
}

function TabButton({name, icon, thisTab, currentTab, onButtonClick}: TabButtonProps): JSX.Element {
    return(
        <Button
            disableRipple
            style={{
                color: "#ff7321",
                backgroundColor: "#0f172b",
                borderRadius: 0,
                marginTop: 0,
                marginLeft: 10,
                marginRight: 10,
                marginBottom: -15,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 3,
                paddingBottom: currentTab === thisTab ? 6 : 10,
                minWidth: 0,
                fontSize: 12,
                fontWeight: currentTab === thisTab ? "500" : "400",
                textTransform: "none",
                borderBottom: currentTab === thisTab ? "5px solid" : "0px",
            }}
            onClick={onButtonClick}>
            <span style={{color: "#ffffff"}}>
                <Box style={{display: "flex"}}>
                    <Box style={{ float: "left", marginRight: "4px", marginTop: "-2px", marginBottom: "-9px" }}>
                        <img src={icon} alt={`${name}-icon`} width="30px" height="30px"/>
                    </Box>
                    <Box style={{ clear: "both" }}>
                        {name}
                    </Box>
                </Box>
            </span>
        </Button>
    );
}

function ShowTab({tabConfig, tabVisibleIndex, id}: ShowTabProps): JSX.Element {
    let tabType: TabType = TabType.NA;
    let index = -1;

    // Find the tab that is selected and get it's absolute index to get the right component
    for(let i = 0; i < tabConfig.length; i += 1) {
        if(tabConfig[i].itemCount !== 0 && tabConfig[i].visibleIndex === tabVisibleIndex) {
            tabType = tabConfig[i].tabType;
            index = i;
        }
    }

    if(!tabConfig[index].access) {
        return <Box style={{paddingBottom: "85px"}}><Alert severity="info">Access denied. Please request the appropriate role from the OTAM team.</Alert></Box>
    }

    // Get the tab component that matches the tab index
    switch (tabType) {
        case TabType.EngineModules:
            return <EnginesGrid id={id} searchCacheKey="engines_installation" useColumnFilters blendToTabs/>
        case TabType.AssetScanData:
            return <AssetScanDataGrid id={id} searchCacheKey="asset_scan_installation" useColumnFilters blendToTabs/>
        case TabType.ESO:
            return <AssetDataGrid type={sourceTypes.ESO} instId={id} searchCacheKey="eso_installation" useColumnFilters blendToTabs/>
        case TabType.SESProp:
            return <AssetDataGrid type={sourceTypes.SESProp} instId={id} searchCacheKey="ses_prop_installation" useColumnFilters blendToTabs/>
        case TabType.SESHybrid:
            return <AssetDataGrid type={sourceTypes.SESHybrid} instId={id} searchCacheKey="ses_hybrid_installation" useColumnFilters blendToTabs/>
        case TabType.Plantnet:
            return <AssetDataGrid type={sourceTypes.Plantnet} instId={id} searchCacheKey="plantnet_installation" useColumnFilters blendToTabs/>
        case TabType.SCR:
            return <AssetDataGrid type={sourceTypes.SCR} instId={id} searchCacheKey="scr_installation" useColumnFilters blendToTabs/>
        case TabType.sWOIS:
            return <AssetDataGrid type={sourceTypes.sWOIS} instId={id} searchCacheKey="swois_installation" useColumnFilters blendToTabs/>
        case TabType.WDCU:
            return <AssetDataGrid type={sourceTypes.WDCU} instId={id} searchCacheKey="wdcu_installation" useColumnFilters blendToTabs/>
        case TabType.PCS:
            return <AssetDataGrid type={sourceTypes.PCS} instId={id} searchCacheKey="pcs_installation" useColumnFilters blendToTabs/>
        case TabType.NA:
        default:
            return <Box style={{paddingBottom: "85px"}}><Alert severity="error" sx={{ mb: 2 }}>Something went wrong opening this tab</Alert></Box>
    }
}

// Used as a workaround for "function declared in a loop contains unsafe references to variable" error
function clickFunction(tabIndex: number, setTab: React.Dispatch<React.SetStateAction<number>>):  React.MouseEventHandler<HTMLButtonElement> {
    return () => setTab(tabIndex);
}

function AssetTabs(
    tabConfig: tabSettings[],
    currentTab: number,
    setTab: React.Dispatch<React.SetStateAction<number>>,
    tabsWithAccess: number,
    assetCount: number,
    blendToGrid = false,
): JSX.Element {
    // Return info box if no assets were found
    if(assetCount === 0) {
        return <Box style={{paddingBottom: "85px"}}><Alert severity="info">No asset data found</Alert></Box>
    }

    if(tabsWithAccess === 0) {
        return <Box style={{paddingBottom: "85px"}}><Alert severity="info">Access denied. Please request the appropriate role(s) from the OTAM team.</Alert></Box>
    }

    const config = tabConfig; // Workaround for "Must use destructuring tabConfig assignment" error
    const tabs = []
    let tabIndex = 0;

    // Create array of React components for the tab buttons
    for(let i = 0; i < config.length; i += 1) {
        if(config[i].itemCount > 0 && config[i].access) {
            tabs.push(
                <React.Fragment key={i}>
                    <TabButton name={config[i].name} icon={config[i].iconPath} thisTab={tabIndex} currentTab={currentTab} onButtonClick={clickFunction(tabIndex, setTab)}/>
                </React.Fragment>
            )
            tabIndex += 1;
        }
    }

    // Return tab buttons
    return(
        <Box>
            <Box className="tabBar" style={{
                marginBottom: blendToGrid ? 0 : "7px",
                borderBottomLeftRadius : blendToGrid ? 0 : "5px",
                borderBottomRightRadius : blendToGrid ? 0 : "5px",
            }}>
                {tabs}
            </Box>
        </Box>
    );
}

export function ShowInstallation(): JSX.Element {
    const { accessToken, config } = useAuth();
    const [tab, setTab] = useState(0);
    const [exporting, setExporting] = useState(false);
    const [exportFailed, setExportFailed] = useState(false);

    const {pathname} = window.location;
    const parts = pathname.split("/");
    const id = parts[2] as string;

    const installationDetails = useInstallationDetails(id);

    // Get how many items each type of asset has
    const moduleCount = useEngineInfoCount(undefined, id, undefined).data;
    const assetScanCount = useAssetScanInfoCount(undefined, id, undefined).data;
    const esoCount = useAssetDataInfoCount(sourceTypes.ESO, id, undefined, undefined).data;
    const sesPropCount = useAssetDataInfoCount(sourceTypes.SESProp, id, undefined, undefined).data;
    const sesHybridCount = useAssetDataInfoCount(sourceTypes.SESHybrid, id, undefined, undefined).data;
    const plantnetCount = useAssetDataInfoCount(sourceTypes.Plantnet, id, undefined, undefined).data;
    const scrCount = useAssetDataInfoCount(sourceTypes.SCR, id, undefined, undefined).data;
    const swoisCount = useAssetDataInfoCount(sourceTypes.sWOIS, id, undefined, undefined).data;
    const wdcuCount = useAssetDataInfoCount(sourceTypes.WDCU, id, undefined, undefined).data;
    const pcsCount = useAssetDataInfoCount(sourceTypes.PCS, id, undefined, undefined).data;

    const bannerIdField = `ID ${id}`;

    // Template for tab configuration
    const tabConfig: tabSettings[] = [
        {
            name: "Engines",
            iconPath: "/img/engine.svg",
            itemCount: 0,
            tabType: TabType.EngineModules,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_engine_data", "otam_engine_data_read"],
            access: false
        },
        {
            name: "Control System",
            iconPath: "/img/control_system_server.svg",
            itemCount: 0,
            tabType: TabType.AssetScanData,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_asset_scan_data", "otam_asset_scan_data_read"],
            access: false
        },
        {
            name: "Energy Storage",
            iconPath: "/img/eso_battery.svg",
            itemCount: 0,
            tabType: TabType.ESO,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_eso_data", "otam_eso_data_read"],
            access: false
        },
        {
            name: "SES Hybrid",
            iconPath: "/img/ses_vessel.svg",
            itemCount: 0,
            tabType: TabType.SESProp,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_ses_data", "otam_ses_data_read"],
            access: false
        },
        {
            name: "SES Hybrid",
            iconPath: "/img/ses_vessel.svg",
            itemCount: 0,
            tabType: TabType.SESHybrid,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_ses_data", "otam_ses_data_read"],
            access: false
        },
        {
            name: "Remote Connectivity",
            iconPath: "/img/firewall.svg",
            itemCount: 0,
            tabType: TabType.Plantnet,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_plantnet_data", "otam_plantnet_data_read"],
            access: false
        },
        {
            name: "Environmental",
            iconPath: "/img/leaflet.svg",
            itemCount: 0,
            tabType: TabType.SCR,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_scr_data", "otam_scr_data_read"],
            access: false
        },
        {
            name: "Data Collection",
            iconPath: "/img/bar_chart.svg",
            itemCount: 0,
            tabType: TabType.WDCU,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_wdcu_data", "otam_wdcu_data_read"],
            access: false
        },
        {
            name: "Data Collection (sWOIS)",
            iconPath: "/img/bar_chart.svg",
            itemCount: 0,
            tabType: TabType.sWOIS,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_swois_data", "otam_swois_data_read"],
            access: false
        },
        {
            name: "Propulsion Control",
            iconPath: "/img/propeller-default.svg",
            itemCount: 0,
            tabType: TabType.PCS,
            visibleIndex: -1,
            roles: ["otam_admin", "otam_pcs_data", "otam_pcs_data_read"],
            access: false
        }
    ]

    // Set item counts for each tab
    tabConfig[0].itemCount = moduleCount ?? 0;
    tabConfig[1].itemCount = assetScanCount ?? 0;
    tabConfig[2].itemCount = esoCount ?? 0;
    tabConfig[3].itemCount = sesPropCount ?? 0;
    tabConfig[4].itemCount = sesHybridCount ?? 0;
    tabConfig[5].itemCount = plantnetCount ?? 0;
    tabConfig[6].itemCount = scrCount ?? 0;
    tabConfig[7].itemCount = wdcuCount ?? 0;
    tabConfig[8].itemCount = swoisCount ?? 0;
    tabConfig[9].itemCount = pcsCount ?? 0;

    let tabCount = 0;
    let assetCount = 0;
    let tabsWithAccess = 0;

    // Count how many tabs have assets and user has access to and assign visible indices
    for(let i = 0; i < tabConfig.length; i += 1) {
        assetCount += tabConfig[i].itemCount;

        if(AuthenticateRolesLocally(tabConfig[i].roles) === RoleAuthResult.RoleFound) {
            tabConfig[i].access = true;
            tabsWithAccess += 1;
        }

        if(tabConfig[i].itemCount > 0) {
            tabConfig[i].visibleIndex = tabCount;
            tabCount += 1;
        }
    }

    async function exportInstallation(installationId: string): Promise<any> {
        await axios
            .get(
                `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/installations/export?inst_id=${installationId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            ).then(response => {
                const link = document.createElement("a");
                link.href = response.data;
                link.setAttribute("download", `Installation-${id}-Assets.xlsx`);
                document.body.appendChild(link);
                link.click();
            }).catch(reason => {
                setExportFailed(true);
                console.log(reason);
            })
    }

    const exportButton = (): void => {
        const instId = installationDetails.data?.installation_id;
        if(instId !== undefined) {
            setExporting(true);
            setExportFailed(false);
            const promise = exportInstallation(String(instId));
            promise.finally(() => { setExporting(false) });
        }
    }

    const IndicatorDisplay = {
        display: "inline-block",
        height: 25,
        width: 25,
        verticalAlign: "middle"
    }

    const IndicatorHidden = {
        display: "none",
        height: 25,
        width: 25,
        verticalAlign: "middle"
    }

    const exportButtonElement =
        <Box style={{ float: "right", display: "inline-block"}}>
            <CircularProgress
                sx={{p: 0}}
                style={exporting ? IndicatorDisplay : IndicatorHidden}
            />
            <Button
                style={{
                    color: exporting ? "#99b7c4" : "#ffffff",
                    padding: "5px 10px 5px 10px",
                    backgroundColor: "#0f334a",
                }}
                variant="text"
                disableRipple
                onClick={(e) => {
                    e.stopPropagation();
                    exportButton();
                    }}
                disabled={exporting}
            >
                Export
            </Button>
        </Box>

    return (
        <Box>
            <Banner firstLine="Installation" secondLine={bannerIdField}/>
            <Container maxWidth="xl">
                <Box>
                    {installationDetails.isLoading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                    {installationDetails.isError && (
                        <Alert severity="error" sx={{ mb: 2 }}>Installation data could not be loaded</Alert>
                    )}
                    {installationDetails.isSuccess && (
                        <Box>
                            <Box className="viewAssetContainer">
                                <AssetInfoBox name="Installation" content={installationInfoContents} data={installationDetails.data} exportButton={exportButtonElement}/>
                            </Box>
                            <Box className="viewAssetContainer">
                                <ApplicationLinksBox id={id}/>
                            </Box>
                            <Box style={{clear: "both", marginTop: 25}}>
                                {exportFailed && (
                                    <Alert severity="error" style={{ marginBottom: 15 }}>Failed to export asset data</Alert>
                                )}

                                <Typography style={{color: "#ff7321", fontWeight: "bold", fontSize: "larger", paddingLeft: 8, float: "left"}}>
                                    Asset Data
                                </Typography>
                                <Box style={{clear: "both", minWidth: "864px"}}>
                                    {AssetTabs(
                                        tabConfig,
                                        tab,
                                        setTab,
                                        tabsWithAccess,
                                        assetCount,
                                        true,
                                    )}
                                    {tabsWithAccess > 0 && assetCount > 0 && <ShowTab tabConfig={tabConfig} tabVisibleIndex={tab} id={id}/>}
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    );
}
