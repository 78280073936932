import jwt from "jwt-decode";
import {useAuth} from "./auth.hooks";
import {sourceTypeDatas, sourceTypes} from "../misc";

interface IRoles {
    roles: string[];
}

export enum RoleAuthResult {
    NoAuthFound = 0,
    NoRoles = 1,
    NoMatchingRole = 2,
    RoleFound = 3,
}

const useTestRoles = false;

const testRolesList: string[] = [
    "otam_internal_read",
]

export function AuthenticateRolesLocally(rolesRequired: string[]): RoleAuthResult {
    const auth = useAuth();
    if (auth.accessToken === "") return RoleAuthResult.NoAuthFound;

    const token : IRoles = jwt(auth.accessToken);
    const userRoles = useTestRoles ? testRolesList : token.roles;

    if(userRoles === undefined) return RoleAuthResult.NoRoles;

    for(let i = 0; i < rolesRequired.length; i += 1) {
        for(let j = 0; j < userRoles.length; j += 1) {
            if(rolesRequired[i] === userRoles[j]) {
                return RoleAuthResult.RoleFound;
            }
        }
    }

    return RoleAuthResult.NoMatchingRole;
}

export function IsAdmin(): boolean {
    return AuthenticateRolesLocally(["otam_admin"]) === RoleAuthResult.RoleFound;
}

export function GetAccessibleTypes(readonlyRoles = false): sourceTypes[] {
    const result: sourceTypes[] = []

    Object.entries(sourceTypeDatas).forEach((e) => {
        const roles = readonlyRoles ? e[1].readonly_roles : e[1].roles;

        if(e[1] !== "-1" && AuthenticateRolesLocally(roles) === RoleAuthResult.RoleFound) {
            result.push(Number(e[0]))
        }
    })

    return result;
}
